<template>
   <div class="entityCardsSetUp">
       <div class="scroll-box">
            <div class="tr-li">
                <div class="lable-txt">读卡器及卡型号:</div>
                <div class="from-input">
                    <el-select v-model="cardModel" placeholder="请选择" >
                        <el-option  label="明华" :value="0" > </el-option>
                        <el-option  label="海鼎" :value="1" > </el-option>
                    </el-select>
                </div>
            </div>
            <div v-if="cardModel==0">
                <div class="tr-li">
                    <div class="lable-txt">端口:</div>
                    <div class="from-input">
                        <el-select v-model="port" placeholder="请选择" :disabled="cardModel!=0">
                            <el-option  label="COM1" :value="0" ></el-option>
                            <el-option  label="COM2" :value="1" ></el-option>
                            <el-option  label="COM3" :value="2" ></el-option>
                            <el-option  label="COM4" :value="3" ></el-option>
                            <el-option  label="COM5" :value="4" ></el-option>
                            <el-option  label="COM6" :value="5" ></el-option>
                            <el-option  label="COM7" :value="6" ></el-option>
                            <el-option  label="COM8" :value="7" ></el-option>
                            <el-option  label="COM9" :value="8" ></el-option>
                        </el-select>
                    </div> 
                </div>
                <div class="tr-li">
                    <div class="lable-txt">波特率:</div>
                    <div class="from-input">
                        <el-select v-model="baud" placeholder="请选择" :disabled="cardModel!=0">
                            <el-option  label="9600" value="9600" ></el-option>
                            <el-option  label="19200" value="19200" ></el-option>
                            <el-option  label="38400" value="38400" ></el-option>
                            <el-option  label="57600" value="57600" ></el-option>
                            <el-option  label="115200" value="115200" ></el-option>
                        </el-select>           
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt"></div>
                    <div class="from-input"><el-checkbox v-model="isMHM1Part" label="使用后8位扇区" :disabled="cardModel!=0"></el-checkbox></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">卡初始密码:</div>
                    <div class="from-input">
                        <input type="password" placeholder="请输入密码" v-model="initPassword" maxlength="12" :disabled="cardModel!=0">
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">制卡后密码:</div>
                    <div class="from-input">
                        <input type="password" placeholder="请输入密码" v-model="password" maxlength="12" :disabled="cardModel!=0">
                    </div>
                </div>
            </div>
            <div v-if="cardModel==1">
                <div class="tr-li">
                    <div class="lable-txt">终端ID:</div>
                    <div class="from-input">
                        <input type="text" v-model="terminationID" maxlength="32" />
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">服务端ID :</div>
                    <div class="from-input">
                        <input type="text" v-model="serverID" maxlength="32" />
                    </div>
                </div>
            </div>
            <!-- <button class="bnt-open" @click="redCard()">大拈会员读卡</button> -->
       </div>
        <div class="footer-box">
            <button class="btn" @click="back()">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
export default {
    name:'entityCardsSetUp',
    data(){
        return {
            cardModel:0,
            /**端口 */
            port:0,
            /**波特率 */
            baud:9600,
            /**明华扇区是否使用后8位*/
            isMHM1Part:false,
            initPassword:'',
            password:'',
            terminationID:'',
            serverID:''
        }
    },
    mounted(){
        this.$webBrowser.getEntityCards().then(d=>{
            console.log(d)
            if(d){
                this.cardModel=d.CardModel;
                this.port=d.Port;
                this.baud=d.Baud;
                this.initPassword=d.InitPassword;
                this.password=d.Password;
                this.terminationID=d.TerminationID;
                this.serverID=d.ServerID;
            }
        })
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                CardModel:this.cardModel,
                Port:this.port,
                Baud:this.baud,
                IsMHM1Part:this.isMHM1Part,
                InitPassword:this.initPassword,
                Password:this.password,
                TerminationID:this.terminationID,
                ServerID:this.serverID
            }
            if(data.CardModel===0){
                if(!(data.Port>=0)){
                    this.$message.warning('端口未设置！');
                    return;
                }
                if(!data.Baud){
                    this.$message.warning('波特率未设置！');
                    return;
                }
                if(!data.InitPassword){
                    this.$message.warning('卡初始密码未设置！');
                    return;
                }
                if(!data.Password){
                    this.$message.warning('制卡后密码未设置！');
                    return;
                }
            }else if(data.CardModel===1){
                if(!data.TerminationID){
                    this.$message.warning('终端ID未设置！');
                    return;
                }
                if(!data.ServerID){
                    this.$message.warning('服务端ID未设置！');
                    return;
                }
            }
            this.$webBrowser.saveEntityCards(data).then(d=>{
                if(d && d.state==0)
                this.$message.success("保存成功");
            });
            
        },
        // redCard(){
        //     this.$webBrowser.redCardM1({type:"daNianVip",password:"a4e9cf37d2b8"});
        // }
    }
}
</script>

<style lang="scss">
@import './entityCardsSetUp.scss';
</style>